import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/adam.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/american-football.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/arrow-cursor.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/arthur.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/features-backgound-right.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/features-background-left.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-background-gradient-mobile.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-background-gradient-xl.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-backgrund-gradient-desktop.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-coins-1.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-coins-2.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-coins-mobile.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-misc-1.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-misc-2.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/hero-misc-mobile.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/lobby-background.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/partners-background.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/quotes-background.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/team-background.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/backgrounds/team-grid.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/banner.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/ben.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/bonham.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/brand-new-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/chris.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/color-picker-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/color-wheel.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/country-flags.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/cursor-badge.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/dollar-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/ESL.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/euro-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/features-phone-1.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/features-phone-2-dark.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/features-phone-2-light.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/features-phone-3.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/features-phone-4.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/england.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/finland.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/france.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/germany.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/india.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/italy.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/japan.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/kenya.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/netherlands.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/norway.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/poland.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/portugal.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/saudi-arabia.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/spain.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/ukraine.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/flags/usa.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/G2.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/casino-step1.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/casino-step2.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/casino-step3.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/lazy-load.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/sports-step1.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/sports-step2.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/sports-step3.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/game/sports-step4.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/insert-logo-icon.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/insert-logo-text.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/iryna.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/jose.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/language-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/language-dropdown.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/lobby-phone-2.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/lobby-phone.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/10Bet.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/888sport.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/alt-logo.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/altenar.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/amazonSlots.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/appsFlyer.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/arland.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/b2Tech.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/BetToMax.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/bragg.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/btoBet.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/captainUp.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/comeOn.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/crabSports.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/EstrelaBet.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/Evona.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/fastrack.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/firebase.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/gig.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/GOAT.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/jacks-testimonial.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/jacks.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/jumpman.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/kambi.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/lucky7Ventures.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/main-logo.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/openBet.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/optimove.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/premierBet.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/prideBet-testimonial.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/prideBet.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/rhino.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/rooBet.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/Rootz.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/salesforce.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/switchAnalytics.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/twilio.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/logos/xtremepush.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/marc.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/marko.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/medal-badge.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/navbar-prize.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/navbar-score.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/navbar-sticker.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/oliver.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/prize-10000.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/ricky.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/star-badge.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-1.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-2.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-3.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-4.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-5.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-6.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-7.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/stickers/sticker-8.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/tennis.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/trophy-badge.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/assets/images/yannis.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/common/AnimatedComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Cursor/Cursor.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Features/Feature.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CoinsLottie"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Features/Markets/CoinsLottie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorWheelLottie"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Features/Solution/ColorWheelLottie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Stickers"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Footer/Stickers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Phones"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Hero/Phones.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Loader/Loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Navbar/NavbarDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Navbar/NavbarMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logos"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Partners/Logos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Swiper"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Quotes/Swiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Swiper"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/Team/Swiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/components/WorkWithUs/WorkWithUs.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src844637576/src/splash-frontend-apps/apps/mkt-site/node_modules/next/dist/client/link.js");
